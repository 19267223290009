import {iosVhFix} from './utils/ios-vh-fix.js';
import {initModals} from './modules/modals/init-modals.js';
import {modalFormHandler} from './modules/modal.js';
import {initPhoneMask} from './modules/phone-mask.js';
import {cookieConsentChecker} from './modules/cookie-consent-checker.js';
import {Burger} from './modules/burger.js';
import {sendMail} from './modules/send-mail.js';
import {slidingMenuInit} from './modules/sliding-menu.js';

window.addEventListener('DOMContentLoaded', () => {
  iosVhFix();

  modalFormHandler();
  initPhoneMask();
  cookieConsentChecker();
  sendMail();
  slidingMenuInit();

  window.addEventListener('load', () => {
    initModals();
    const burger = new Burger();
    burger.init();
  });
});
