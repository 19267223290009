const header = document.querySelector('[data-header]');

export const slidingMenuInit = () => {
  if (window.innerWidth <= 767) {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      if (Math.abs(prevScrollpos - currentScrollPos) > 70) {
        if (prevScrollpos > currentScrollPos) {
          header.style.top = '0';
        } else {
          header.style.top = '-87px';
        }
        prevScrollpos = currentScrollPos;
      }
    };
  }
};
