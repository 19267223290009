import { closeModalForm } from "./modal";

const feedbackForm = document.querySelector('[data-feedback-form="feedback-form"]');
const feedbackButton = document.querySelector('[data-feedback-button="feedback-button"]');

export const sendMail = () => {
  feedbackForm.addEventListener('submit', formSend);

  async function formSend (evt) {
    evt.preventDefault();

    let formData = new FormData(feedbackForm);

    feedbackForm.classList.add('_sending');
    feedbackButton.disabled = true;

    let response = await fetch('send.php', {
        method: 'POST',
        body: formData
      }
    );

    if (response.ok) {
      feedbackForm.reset();
      alert('Успешно отправлено!');
      feedbackForm.classList.remove('_sending');
      feedbackButton.disabled = false;
      closeModalForm();
    } else {
      alert('Ошибка отправки');
      feedbackForm.classList.remove('_sending');
      feedbackButton.disabled = false;
      closeModalForm();
    }
  };
}
